import React, { useState } from 'react';
import { motion } from 'framer-motion';
import FAQItem from './FAQItem';

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  // FAQ data
  const faqs = [
    {
      question: "What make ITArise different from other software development companies?",
      answer: "Our commitment to quality, transparency, and customer satisfaction sets us apart. We work closely with our clients to deliver reliable, user-centric solutions that drive business growth."
    },
    {
      question: "Do you offer ongoing support and maintenance?",
      answer: "Yes, we provide post-launch support and maintenance to ensure your website or application runs smoothly. This includes software updates, bug fixes, security checks, and other ongoing services to keep your digital solutions optimized."
    },
    {
      question: "What types of software development services do you offer?",
      answer: "We specialize in website development, e-commerce solutions, and custom software development. Our services cover everything from building responsive websites and secure e-commerce platforms to developing tailored software that addresses specific business needs."
    }
  ];

  return (
    <section className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center max-w-3xl mx-auto mb-20"
        >
          <h2 className="text-4xl font-bold mb-6">Frequently Asked Questions</h2>
          <p className="text-xl text-gray-600">
            Find answers to common questions about our services
          </p>
        </motion.div>

        {/* FAQ Items */}
        <div className="max-w-4xl mx-auto">
          {faqs.map((faq, index) => (
            <FAQItem
              key={index}
              question={faq.question}
              answer={faq.answer}
              isOpen={openIndex === index}
              onToggle={() => setOpenIndex(openIndex === index ? null : index)}
              index={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection; 